/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Alert from '../components/Alert';
import Button from '../components/Button';
import Container from '../components/Container';
import FullHeightColumn from '../components/FullHeightColumn';
import { SearchIcon } from '../components/Icon';
import Layout from '../components/layout';
import Spinner from '../components/Spinner';
import ServicePointButton from '../parcelFlow/ServicePointButton';
import { selectDeparturePoint } from '../state/basket';
import { locNavigate } from '../state/session';
import * as analytics from '../utils/analytics';
import { autocompleteAddress, getLargeParcelDeparturePoints } from '../utils/api';
import getLanguage, { useTranslate } from '../utils/getLanguage';

const CenteredHelpText = ({ children }) => (
  <Styled.p
    sx={{
      p: [4],
      color: 'gray',
      textAlign: 'center',
    }}
  >
    {children}
  </Styled.p>
);

const ServicePointForm = () => {
  const language = useSelector(state => getLanguage(state));
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [servicePoints, setServicePoints] = useState([]);
  const departurePoint = useSelector(state => state.basket.departurePoint);
  const selectedPointInList = departurePoint && !!servicePoints.find(s => s.officeCode === departurePoint.officeCode);
  const servicePoints2 = departurePoint && !selectedPointInList ? [departurePoint, ...servicePoints] : servicePoints;

  const onNextClick = useCallback(() => {
    dispatch(locNavigate('/sender'));
  }, [dispatch]);

  const selectSuggestion = useCallback(
    async opt => {
      const postcode = opt.postalCode;
      const street = opt.streetAddress;
      setLoading(true);
      let servicePoints = [];
      servicePoints = await getLargeParcelDeparturePoints({
        postcode,
        street,
        language,
      });
      setLoading(false);
      setServicePoints(servicePoints);
    },
    [setServicePoints, setLoading, language]
  );

  const selectStyles = {
    container: base => ({
      ...base,
      display: 'inline-block',
      width: 'calc(100% - 40px)',
      verticalAlign: 'bottom',
    }),
    control: base => ({ ...base, border: 'none', boxShadow: 'none' }),
    indicatorsContainer: base => ({ display: 'none' }),
  };

  return (
    <Flex
      sx={{
        flex: ['auto', null, 'none'],
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: 640,
      }}
    >
      <Alert>{translate('departurePlace.note')}</Alert>
      <Box sx={{ flex: 'auto', my: 3 }}>
        <div
          sx={{
            border: 0,
            borderBottomColor: 'secondary',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderRadius: 0,
          }}
        >
          <SearchIcon sx={{ display: 'inline-block' }} />
          <AsyncSelect
            placeholder={translate('buyParcel.pickup.search')}
            styles={selectStyles}
            loadOptions={term => autocompleteAddress(term, language)}
            getOptionLabel={opt => opt.name}
            isOptionSelected={() => false}
            onChange={selectSuggestion}
            noOptionsMessage={() => null}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />
        </div>
        <Box sx={{ mt: 2 }}>
          {isLoading ? (
            <Spinner />
          ) : !departurePoint && servicePoints.length === 0 ? (
            <CenteredHelpText>{translate('buyParcel.repack.officeSearchNote')}</CenteredHelpText>
          ) : (
            servicePoints2.map(s => (
              <ServicePointButton
                key={s.officeCode}
                servicePoint={s}
                isSelected={departurePoint && s.officeCode === departurePoint.officeCode}
                onSelect={() => {
                  dispatch(selectDeparturePoint(s));
                }}
              />
            ))
          )}
        </Box>
      </Box>
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button disabled={!departurePoint} onClick={onNextClick}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ({ pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = useTranslate();
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(locNavigate('/shopping-cart')), [dispatch]);

  return (
    <Layout
      title={translate('departurePlace.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      showNavigation={false}
    >
      <Container variant="parcel">
        <FullHeightColumn>
          <Box>
            <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
              {translate('buyParcel.backButton')}
            </Button>
          </Box>
          <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('departurePlace.title')}</Styled.h1>
          <ServicePointForm />
        </FullHeightColumn>
      </Container>
    </Layout>
  );
};
